<template>
  <div class="mb-3">
    <CRow class="my-3">
        <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-end">
            <CButton
                color="excel"
                class="mr-1" 
                size="sm"
                @click="onBtnExport(true)"
            >
                <CIcon name="cil-file-excel"/>&nbsp; XSLX
            </CButton>
        </CCol>
    </CRow>

    <CRow class="mt-2">
        <CCol sm="12">
            <dataTableExtended
                class="align-center-row-datatable"
                :items="computedListContainer"
                :fields="blfields"
                :items-per-page="5"
                column-filter
                pagination
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :noItemsView="tableText.noItemsViewText"
                :loading="loading"
                sorter
            >
            
                <template #options="{ item }">
                    <td class="text-center">
                        <CButton
                        square
                        size="sm"
                        color="watch"
                        class="d-flex align-items-center"
                        @click="toggleContainerEdit(item)"
                        v-c-tooltip="{
                            content: $t('label.bl'),
                            placement: 'top-end'
                        }"
                        >
                        <CIcon name="eye" />
                        </CButton>
                    
                    </td>
                </template>
            </dataTableExtended>
        </CCol>
    </CRow>
    <ModalBlContainer
      :modal.sync="ModalBlContainer"
      :ContainerItem="ContainerItem"
      @Update-list="Update"
    />
  </div>
</template>
<script>
    import General from '@/_mixins/general';
    import ModalBlContainer from './modal-bl-container';
    import { mapState } from 'vuex';
    import ReportSearchContainer from '@/_mixins/reportsSearchContainer';
    import ENUM from '@/_store/enum';
    import { NumberFormater, trimRemoveSpecialCharacters } from '@/_helpers/funciones';
    
    

    //data
    function data() {
        return {
            Items: [],
            loading: false,
            ModalBlContainer: false,
            ContainerItem:{},
            dataBl: [],
        };
    }

    //methods
    function toggleContainerEdit(item) {
        this.ContainerItem = item;
        this.ModalBlContainer = !this.ModalBlContainer;
    }

    function Update() {
        this.ModalBlContainer = false;
    }


    async function onBtnExport(valor) {
        let _lang = this.$i18n.locale;
        this.$store.state.containerSearch.loading = ENUM.LOADING;
        let tableHeader = {label:[], key:[]};
        let rowDataFormated = [];

        this.blfields.map(item=>{   
            if (item.key!='options') {
                tableHeader.label.push(item.label);
                tableHeader.key.push(item.key);
            }
        })

        for (let i = 0; i < this.dataBl.length; i++) {
            rowDataFormated.push({
                RowNumber: this.dataBl[i].IdX,
                BlNro: this.dataBl[i].BlNro ?? 'N/A',
                ConsigneeName: this.dataBl[i].ConsigneeName ?? 'N/A',
                CustomBrokerName: this.dataBl[i].CustomBrokerName ?? 'N/A',
                CustomsRegime: this.dataBl[i].CustomOperationDsEs ?? 'N/A',
                DescriptionOfGoods: this.dataBl[i].DescriptionOfGoods ? `${this.dataBl[i].DescriptionOfGoods.substr(0,30)}...` : 'N/A',
                Dua: this.dataBl[i].Dua ?? 'N/A',
                ReceptionBpAct: this.dataBl[i].ReceptionBpAct ?? 'N/A',
                BookingNro: this.dataBl[i].BookingNro ?? 'N/A',
                NotifyToName: this.dataBl[i].NotifyToName ?? 'N/A',
                Procedence: this.dataBl[i].Procedence ?? 'N/A',
                Weigth: NumberFormater.formatNumber(this.dataBl[i].Weigth,2) ?? 'N/A',
                Volumen: this.dataBl[i].Volumen ?? 'N/A',
                Quantity: this.dataBl[i].Quantity ?? 'N/A',
            });   
        }
        if(this.nulo!="null" && this.dataBl.length !== 0) {
            await this.generateExcel(rowDataFormated, this.$t('label.bl'), valor, tableHeader.label, false, tableHeader, 'SERVICIOS LOGISTICOS OCK', this.$store.state.containerSearch.dataContainer);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.$store.state.containerSearch.loading = ENUM.LOADED;
    }

    //computeds
    function computedListContainer() {
        return  this.dataBl.map((item) => Object.assign({}, item, {
            RowNumber: item.IdX,
            BlNro: item.BlNro ?? 'N/A',
            ConsigneeName: item.ConsigneeName ?? 'N/A',
            CustomBrokerName: item.CustomBrokerName ?? 'N/A',
            CustomsRegime: item.CustomOperationDsEs ?? 'N/A',
            DescriptionOfGoods: item.DescriptionOfGoods ? trimRemoveSpecialCharacters(item.DescriptionOfGoods) : '',
            Dua: item.Dua ?? 'N/A',
            ReceptionBpAct: item.ReceptionBpAct ?? 'N/A',
            BookingNro: item.BookingNro ?? 'N/A',
            NotifyToName: item.NotifyToName ?? 'N/A',
            Procedence: item.Procedence ?? 'N/A',
            Weigth: NumberFormater.formatNumber(item?.Weigth,2) ?? 'N/A',
            Volumen: item.Volumen ?? 'N/A',
            Quantity: item.Quantity ?? 'N/A',
        }));
    }

    function blfields(){ 
        return [
            { key: 'options', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
            { key: 'RowNumber', label: '#', _style: 'width:3%; text-align:center', _classes: 'center-cell text-center', filter: false },
            { key: 'BlNro', label: this.$t('label.bl'),  _classes: 'text-uppercase text-center', _style: 'min-width:160px; text-align:center;', sorter: true, filter: true},
            { key: 'ConsigneeName', label: this.$t('label.consignee'), _style:'min-width:150px;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
            { key: 'CustomBrokerName', label: this.$t('label.customBroker'),  _style:'min-width:170px; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
            { key: 'CustomsRegime', label: this.$t('label.customsRegime'),  _style:'min-width:220px; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
            { key: 'BookingNro', label: this.$t('label.nBooking'),  _classes: 'text-uppercase text-center', _style: 'min-width:180px; text-align:center;', sorter: true, filter: true},
            { key: 'DescriptionOfGoods', label: this.$t('label.inventoryContains'), _style:'min-width:220px; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true},
            { key: 'Dua', label: this.$t('label.nDua'), _style:'min-width:140px; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true},
            { key: 'ReceptionBpAct', label: this.$t('label.bolipuertoReceptionRecordNro'), _style:'min-width:300px; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true},
            { key: 'Procedence', label: this.$t('label.Origin'),  _style:'min-width:150px; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
            { key: 'Weigth', label: `${this.$t('label.weight')}(KGM)`,  _style:'min-width:100px; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true },
            { key: 'Volumen', label: `${this.$t('label.volume')}(M³)`,  _style:'min-width:135px; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
            { key: 'Quantity', label: this.$t('label.quantity'),  _style:'min-width:110px; text-align:center;', _classes:"text-center" , sorter: true, filter: true},
        ];
    }

    export default{
        name: 'bl-list',
        data,
        mixins: [General, ReportSearchContainer],
        components: {
            ModalBlContainer,
        },
        methods: {
            Update,
            toggleContainerEdit,
            onBtnExport
        },
        computed: {
            blfields,
            computedListContainer,
            ...mapState({
                dataContainer: state => state.containerSearch.dataContainer,
            })
        },
        watch:{
            dataContainer: function (newValue) {
                if (Object.keys(newValue).length > 0 && this.$store.state.containerSearch.dataContainer?.BlJson){
                    this.dataBl = this.$store.state.containerSearch.dataContainer?.BlJson;
                }
                    
                else
                    this.dataBl = [];
                
            },
        }
    }
</script>
<style lang="scss">

    .icon{
        background-color: null; 
        border: 0px;
    }
    .center-cell {
        text-align: center;
    }
    .table-index {
        table {
            td {
            vertical-align: middle !important;
            }
        }
    }
</style>