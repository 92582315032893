import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';
import {saveAs} from "file-saver";
import Excel from "exceljs/dist/es5/exceljs.browser";
import { mapState } from 'vuex';
import { isUndefined } from 'lodash';
import ENUM from '@/_store/enum';
import { formatMilDecimal } from '@/_validations/validacionEspeciales';

function data() {
  return {
    dataBuque:{
      CountryName:'',
      DatePlanning:'',
      VesselFlagRoute:'',
    },
    items: [],
  }
}

function numeroLetra(valor) {
    const abc = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AU", "AV", "AW", "AX", "AY", "AZ", "BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BK", "BL", "BM", "BN", "BO", "BP", "BQ", "BR", "BS", "BT", "BU", "BV", "BW", "BX", "BY", "BZ"];

    return abc[valor - 1];

}

function methodFormated(item) {
    if(item)
        return  NumberFormater.formatNumber(item, 2) ?? 'N/A'
    else
        return  'N/A'
}

function methodDate(date) {
    if(date)
        return  DateFormater.formatDateTimeWithSlash(date)
    else
        return  'N/A'
}


function cells(cellTitle, column, row, worksheet, top = false, left = false, right = false, bottom = false, columnSize = 20, height = 30, typeFont, size = 12, bold = false, horizontal = 'center', columnMerge = "", rowMerge = "", bgColor = "", color = "", headerRow = false) {

    // WIDTH OF CELLS
    worksheet.getColumn(column).width = columnSize;

    //MERGE COLUMNS
    if(columnMerge != "" && rowMerge != ""){
        worksheet.mergeCells(`${column}${row}:${columnMerge}${rowMerge}`);
    }


    //BORDERS
    let borders = {};

    if(left)
        borders['left'] = { style: 'thin' };
    if(right)
        borders['right'] = { style: 'thin' };
    if(bottom)
        borders['bottom'] = { style: 'thin' };
    if(top)
        borders['top'] = { style: 'thin' };

    //PROPERTIES CELLS
    let custom

    if(headerRow && row == '')
        custom = headerRow.getCell(column);
    
    else
        custom = worksheet.getCell(`${column}${row}`);
    
    let rowHeight = worksheet.getRow(row);
    rowHeight.height = height;

    custom.font = { 
        name: typeFont, 
        family: 4,
        size: size, 
        underline: false, 
        color: { argb: color!='' ? color : 'black' },
        bold: bold 
    };

    if(left || right || bottom || top  ){
        custom.border = borders;
    }

    if(bgColor !='')
        custom.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: bgColor
                
            }
        }

    custom.value = cellTitle;
    
    custom.alignment = { 
        wrapText: true, 
        vertical: 'middle',
        horizontal: horizontal
    }

    return custom;
}

async function getPdf(rowData, titulo, optionHeaderReport, colDelete) {
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");
    let columns = [];
    //if (isUndefined(un)) un = 'TON';

    for (let index = 0; index < this.columnDefs.length; index++) {
        //if (this.columnDefs[index].headerName.toUpperCase() == "WEIGTH") this.columnDefs[index].headerName = this.columnDefs[index].headerName + " (" + un + ")";
        columns.push({
            title: this.columnDefs[index].headerName,
            dataKey: this.columnDefs[index].field
        });
    }

    if (colDelete) {
        columns.splice(colDelete.from-1, colDelete.to);
    }

    const response1 = await this.$http.getFile('base64', {
        path:this.user.LogoRoute.replace('Public/','', null, 'i')
    });
    const divisiones1 = this.user.LogoRoute.split(".");
    const myBase64Image = 'data:image/'+divisiones1[1]+';base64,'+response1.data.data.base64;

    let font = 7;
    let widthImage = 80;
    let sizeText = 8;
    let tableWidthBody = 'auto';
    
    if (columns.length < 12) {
        font=7; 
    } else {
        font=5; 
    }

    const doc = new jsPDF({
        orientation: 'l',
        unit: 'pt', 
        format: [612, 792],
        putOnlyUsedFonts:true,
        floatPrecision: 16 // or "smart", default is 16
    });

    const addFooters = doc => {
        const pageCount = doc.internal.getNumberOfPages();
        const hoy = new Date();
        //let fecha = hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
        let fecha  = DateFormater.formatTimeZoneToDateTime(hoy);
        doc.setFontSize(font);
        doc.setTextColor("#000");
        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i)
            doc.text(this.$t('label.printed')+": "+ fecha, 40, 570, {
                align: 'left'
            });
            if(this.loadText !== undefined && this.loadText !== null){
                doc.text(this.$t('label.user')+": " + this.user.UserName + " " + this.user.UserLastName + " / LOAD LIST FILTER BY: "+this.loadText, doc.internal.pageSize.width - 50, 570, {
                    align: 'right'
                });
            }else{
                doc.text(this.$t('label.user')+": " + this.user.UserName + " " + this.user.UserLastName, doc.internal.pageSize.width - 50, 570, {
                    align: 'right'
                });
            }
            doc.text(this.$t('label.pageReport')+ ' ' + String(i)+ ' ' + this.$t('label.ofReport')+ ' ' + String(pageCount), doc.internal.pageSize.width - 50, 585, {           
                align: 'right'
            })
        }
    }

    let voyajeData = '';
    if(this.itinerarySelected !== '' || this.itinerarySelected !== null){
        this.dataBuque.DatePlanning = this.itinerarySelected.VesselName+" "+this.itinerarySelected.Imo+", "+this.itinerarySelected.CountryName+", "+this.$t('label.berth')+': '+this.itinerarySelected.BerthName;
        this.dataBuque.CountryName = this.itinerarySelected.CountryName;
        this.dataBuque.VesselFlagRoute = this.itinerarySelected.VesselFlagRoute;
        voyajeData = this.itinerarySelected.LabelArrival+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Arrival)+", "+this.itinerarySelected.LabelDeparture+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Departure)+" - "+this.$t('label.voyage')+": "+this.itinerarySelected.Voyage;
    }

    doc.setDrawColor("#fff");
    doc.addImage(myBase64Image, "JPEG", 43, 43, widthImage, 63);

    if(this.dataBuque.DatePlanning != ''){
        let dataInfo = [];
        dataInfo.push({ name: this.user.CompanyName + ' ' + this.user.CompanyRif + ' - ' +this.user.BranchJson[0].BranchName });
        dataInfo.push({ name: this.dataBuque.DatePlanning });
        dataInfo.push({ name: voyajeData });

        let columns = [];
        let columnDefs = Object.keys(dataInfo[0]);

        for (let index = 0; index < columnDefs.length; index++) {
            columns.push({
                dataKey: columnDefs[index]
            });
        }
        let tableWidthBody = widthImage+45;
        let ancho = 626;
        doc.autoTable(columns, dataInfo, {
            startY: 60,
            theme: 'plain',
            columnWidth: (ancho),
            showHeader: 'everyPage',
            tableLineColor: 200,
            tableLineWidth: 0,
            
            tableWidth: ancho,
            margin: { left: tableWidthBody },
            styles: { 
                cellPadding: { bottom: 2 },
                margin: { bottom: 2 },
                fontSize: sizeText, 
                halign: "center", 
                valign: "middle",
                cellWidth: (ancho),
            },
            headStyles: {
                textColor: "#000",
                halign: "center",
            },
        });
    }

    doc.setFontSize(sizeText);
    doc.setFillColor("#cccccc");
    doc.rect(40, 105, 712, 3, 'F');

    doc.setFillColor("#cccccc");
    doc.rect(40, 110, 712, 25, "FD");
    doc.setFontSize(sizeText);

    if(titulo != ''){
        let dataInfo = [];
        dataInfo.push({ name: titulo });

        let columns = [];
        let columnDefs = Object.keys(dataInfo[0]);

        for (let index = 0; index < columnDefs.length; index++) {
            columns.push({
                dataKey: columnDefs[index]
            });
        }
        let tableWidthBody = 43;
        let ancho = widthImage+629;
        doc.autoTable(columns, dataInfo, {
            startY: 118,
            theme: 'plain',
            columnWidth: (ancho),
            showHeader: 'everyPage',
            tableLineColor: 200,
            tableLineWidth: 0,
            
            tableWidth: ancho,
            margin: { left: tableWidthBody },
            styles: { 
                cellPadding: { bottom: 2 },
                margin: { bottom: 2 },
                fontSize: sizeText, 
                halign: "center", 
                valign: "middle",
                cellWidth: (ancho),
            },
            headStyles: {
                textColor: "#000",
                halign: "center",
            },
        });
    }
    let inicio = 145;
    doc.autoTable(columns, rowData, {
        startY: inicio,
        theme: 'grid',
        showHeader: 'everyPage',
        tableLineColor: 200,
        tableLineWidth: 0,
        margin: { bottom: 45 },
        tableWidth: tableWidthBody,
        styles: { 
            cellPadding: 1, 
            fontSize: font, 
            halign: "center", 
            valign: "middle"
        },
        headStyles: {
            fillColor: "#EEE",
            textColor: "#000",
            halign: "center",
        },
        columnStyles: {
            overflow: 'linebreak',
        },
        didParseCell: function (rowData) {
            if(optionHeaderReport == 'containerByPortLineSummary'){
                if (rowData.row.section === "body" && rowData.row.index === 0) {
                    rowData.cell.styles.fillColor = [237, 237, 237];    
                    rowData.cell.styles.textColor = [255, 255, 255];

                    if(rowData.column.index >= 0 && rowData.column.index <= 2){//CELDAS COMBINADAS GRIS CLARO
                        rowData.cell.colSpan = 3;
                    }
                
                    if(rowData.column.index >= 3 && rowData.column.index <= 4){//CELDAS COMBINADAS AZUL
                        rowData.cell.styles.fillColor = [26,55,96];
                        rowData.cell.colSpan = 2;
                    }
                    if(rowData.column.index >= 5 && rowData.column.index <= 6){//CELDAS COMBINADAS AZUL
                        rowData.cell.styles.fillColor = [26,55,96];
                        rowData.cell.colSpan = 2;
                    }
                    if(rowData.column.index >= 7 && rowData.column.index <= 8){//CELDAS COMBINADAS NARANJA
                        rowData.cell.styles.fillColor = [236,99,27];
                        rowData.cell.colSpan = 2;
                    }
                    if(rowData.column.index >= 9 && rowData.column.index <= 10){//CELDAS COMBINADAS NARANJA
                        rowData.cell.styles.fillColor = [236,99,27];
                        rowData.cell.colSpan = 2;
                    }
                    if(rowData.column.index >= 11 && rowData.column.index <= 12){//CELDAS COMBINADAS GRIS OSCURO
                        rowData.cell.styles.fillColor = [93,97,100];
                        rowData.cell.colSpan = 2;
                    }
                    if(rowData.column.index >= 13 && rowData.column.index <= 14){//CELDAS COMBINADAS GRIS OSCURO
                        rowData.cell.styles.fillColor = [93,97,100];
                        rowData.cell.colSpan = 2;
                    }
                }
            } else if(optionHeaderReport == 'imdgContainerSummary'){
                if (rowData.row.section === "body" && rowData.row.index === 0) {
                    rowData.cell.styles.fillColor = [237, 237, 237];
                    rowData.cell.styles.textColor = [255, 255, 255];

                    if(rowData.column.index >= 0 && rowData.column.index <= 1){//CELDAS COMBINADAS GRIS CLARO
                        rowData.cell.colSpan = 2;
                    }
                
                    if(rowData.column.index >= 2 && rowData.column.index <= 5){//CELDAS COMBINADAS AZUL
                        rowData.cell.styles.fillColor = [26,55,96];
                        rowData.cell.colSpan = 4;
                    }
                    
                    if(rowData.column.index >= 6 && rowData.column.index <= 9){//CELDAS COMBINADAS NARANJA
                        rowData.cell.styles.fillColor = [236,99,27];
                        rowData.cell.colSpan = 4;
                    }

                    if(rowData.column.index >= 10 && rowData.column.index <= 13){//CELDAS COMBINADAS GRIS OSCURO
                        rowData.cell.styles.fillColor = [93,97,100];
                        rowData.cell.colSpan = 4;
                    }
                }
            } else if(optionHeaderReport == 'delayList'){
                if (rowData.row.section === "body" && rowData.row.index === 0) {
                    rowData.cell.styles.fillColor = [237, 237, 237];
                    rowData.cell.styles.textColor = [255, 255, 255];

                    if(rowData.column.index >= 0 && rowData.column.index <= 12){//CELDAS COMBINADAS GRIS CLARO
                        rowData.cell.colSpan = 13;
                    }
                
                    if(rowData.column.index >= 13 && rowData.column.index <= 15){//CELDAS COMBINADAS AZUL
                        rowData.cell.styles.fillColor = [26,55,96];
                        rowData.cell.colSpan = 3;
                    }
                    
                    if(rowData.column.index >= 16 && rowData.column.index <= 18){//CELDAS COMBINADAS NARANJA
                        rowData.cell.styles.fillColor = [236,99,27];
                        rowData.cell.colSpan = 3;
                    }

                    if(rowData.column.index >= 19 && rowData.column.index <= 21){//CELDAS COMBINADAS GRIS CLARO
                        rowData.cell.colSpan = 3;
                    }
                }
            }  else if(optionHeaderReport == 'transportSummary'){
                if (rowData.row.section === "body" && rowData.row.index === 0) {
                    rowData.cell.styles.fillColor = [237, 237, 237];
                    rowData.cell.styles.textColor = [255, 255, 255];

                    if(rowData.column.index >= 0 && rowData.column.index <= 2){//CELDAS COMBINADAS GRIS CLARO
                        rowData.cell.colSpan = 3;
                    }
                
                    if(rowData.column.index >= 3 && rowData.column.index <= 8){//CELDAS COMBINADAS AZUL
                        rowData.cell.styles.fillColor = [26,55,96];
                        rowData.cell.colSpan = 6;
                    }
                    
                    if(rowData.column.index >= 9 && rowData.column.index <= 11){//CELDAS COMBINADAS NARANJA
                        rowData.cell.styles.fillColor = [236,99,27];
                        rowData.cell.colSpan = 3;
                    }

                    if(rowData.column.index >= 12 && rowData.column.index <= 14){//CELDAS COMBINADAS GRIS OSCURO
                        rowData.cell.styles.fillColor = [93,97,100];
                        rowData.cell.colSpan = 3;
                    }
                }
            }else if(optionHeaderReport == 'TypeLoadPerCraneSummary'){
                if (rowData.row.section === "body" && rowData.row.index === 0) {
                    rowData.cell.styles.fillColor = [237, 237, 237];
                    rowData.cell.styles.textColor = [255, 255, 255];

                    if(rowData.column.index == 2){//CELDAS COMBINADAS AZUL
                        rowData.cell.styles.fillColor = [26,55,96];
                        rowData.cell.colSpan = rowData.row.raw.DeckMax+2;
                    }
                    
                    if(rowData.column.index >= (4+rowData.row.raw.DeckMax)){//CELDAS COMBINADAS NARANJA
                        rowData.cell.styles.fillColor = [236,99,27];
                        rowData.cell.colSpan = rowData.row.raw.HoldMax+2;
                    }
                }
            }    
        },
    });
  
    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/","");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();

    addFooters(doc);
    doc.save(`${fileName}${today}${time}.pdf`);//
    this.$notify({
        group: 'container',
        text: this.$t('label.reportSuccessfully'),
        type: "success"
    });
}

async function getPdfArray(rowData, titulo) {
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");
    let columns = [];
    let columnDefs = Object.keys(rowData[0]);
    
    for (let index = 0; index < columnDefs.length; index++) {
        columns.push({
            title: columnDefs[index],
            dataKey: columnDefs[index]
        });
    }
    
    const response1 = await this.$http.getFile('base64', {
        path: this.user.LogoRoute.replace('Public/', '', null, 'i')
    });
    const divisiones1 = this.user.LogoRoute.split(".");
    const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;

    let font = 7;
    let widthImage = 80;
    let sizeText = 8;
    let tableWidthBody = 'auto';
    
    if (columns.length < 12) {
        font=7; 
    } else {
        font=5; 
    }
    const doc = new jsPDF({
        orientation: 'l',
        unit: 'pt',
        format: [612, 792],
        putOnlyUsedFonts: true,
        floatPrecision: 16 // or "smart", default is 16
    });

    const addFooters = doc => {
        const pageCount = doc.internal.getNumberOfPages();
        const hoy = new Date();
        //let fecha = hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
        let fecha  = DateFormater.formatTimeZoneToDateTime(hoy);

        doc.setFontSize(font);
        doc.setTextColor("#000");
        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i)
            doc.text(this.$t('label.printed')+": "+ fecha, 40, 570, {
                align: 'left'
            });
            if (this.loadText !== undefined && this.loadText !== null) {
                doc.text(this.$t('label.user')+": " + this.user.UserName + " " + this.user.UserLastName + " / LOAD LIST FILTER BY: " + this.loadText, doc.internal.pageSize.width - 50, 570, {
                    align: 'right'
                });
            } else {
                doc.text(this.$t('label.user')+": "+ this.user.UserName + " " + this.user.UserLastName, doc.internal.pageSize.width - 50, 570, {
                    align: 'right'
                });
            }
            doc.text(this.$t('label.pageReport')+ ' ' + String(i)+ ' ' + this.$t('label.ofReport')+ ' ' + String(pageCount), doc.internal.pageSize.width - 50, 585, {
                align: 'right'
            })
        }
    }

    let voyajeData = '';
    if(this.itinerarySelected !== '' || this.itinerarySelected !== null){
        this.dataBuque.DatePlanning = this.itinerarySelected.VesselName+" "+this.itinerarySelected.Imo+", "+this.itinerarySelected.CountryName+", "+this.$t('label.berth')+': '+this.itinerarySelected.BerthName;
        this.dataBuque.CountryName = this.itinerarySelected.CountryName;
        this.dataBuque.VesselFlagRoute = this.itinerarySelected.VesselFlagRoute;
        voyajeData = this.itinerarySelected.LabelArrival+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Arrival)+", "+this.itinerarySelected.LabelDeparture+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Departure)+" - "+this.$t('label.voyage')+": "+this.itinerarySelected.Voyage;
    }

    doc.setDrawColor("#fff");
    doc.addImage(myBase64Image, "JPEG", 43, 43, widthImage, 63);

    doc.setDrawColor("#fff");
    doc.addImage(myBase64Image, "JPEG", 43, 43, widthImage, 63);

    if(this.dataBuque.DatePlanning != ''){
        let dataInfo = [];
        dataInfo.push({ name: this.user.CompanyName + ' ' + this.user.CompanyRif + ' - ' +this.user.BranchJson[0].BranchName });
        dataInfo.push({ name: this.dataBuque.DatePlanning });
        dataInfo.push({ name: voyajeData });

        let columns = [];
        let columnDefs = Object.keys(dataInfo[0]);

        for (let index = 0; index < columnDefs.length; index++) {
            columns.push({
                dataKey: columnDefs[index]
            });
        }
        let tableWidthBody = widthImage+45;
        let ancho = 626;
        doc.autoTable(columns, dataInfo, {
            startY: 60,
            theme: 'plain',
            columnWidth: (ancho),
            showHeader: 'everyPage',
            tableLineColor: 200,
            tableLineWidth: 0,
            
            tableWidth: ancho,
            margin: { left: tableWidthBody },
            styles: { 
                cellPadding: { bottom: 2 },
                margin: { bottom: 2 },
                fontSize: sizeText, 
                halign: "center", 
                valign: "middle",
                cellWidth: (ancho),
            },
            headStyles: {
                textColor: "#000",
                halign: "center",
            },
        });
    }

    doc.setFontSize(sizeText);
    doc.setFillColor("#cccccc");
    doc.rect(40, 105, 712, 3, 'F');

    doc.setFillColor("#cccccc");
    doc.rect(40, 110, 712, 25, "FD");
    doc.setFontSize(sizeText);

    if(titulo != ''){
        let dataInfo = [];
        dataInfo.push({ name: titulo });

        let columns = [];
        let columnDefs = Object.keys(dataInfo[0]);

        for (let index = 0; index < columnDefs.length; index++) {
            columns.push({
                dataKey: columnDefs[index]
            });
        }
        let tableWidthBody = 43;
        let ancho = widthImage+629;
        doc.autoTable(columns, dataInfo, {
            startY: 118,
            theme: 'plain',
            columnWidth: (ancho),
            showHeader: 'everyPage',
            tableLineColor: 200,
            tableLineWidth: 0,
            
            tableWidth: ancho,
            margin: { left: tableWidthBody },
            styles: { 
                cellPadding: { bottom: 2 },
                margin: { bottom: 2 },
                fontSize: sizeText, 
                halign: "center", 
                valign: "middle",
                cellWidth: (ancho),
            },
            headStyles: {
                textColor: "#000",
                halign: "center",
            },
        });
    }

    //let arreglo = rowData.shift();
    let inicio = 145;
    doc.autoTable(columns, rowData, {
        startY: inicio,
        theme: 'grid',
        showHeader: 'everyPage',
        tableLineColor: 200,
        tableLineWidth: 0,
        
        tableWidth: tableWidthBody,
        styles: { 
            cellPadding: 1, 
            fontSize: font, 
            halign: "center", 
            valign: "middle"
        },
        headStyles: {
            fillColor: "#EEE",
            textColor: "#000",
            halign: "center",
        },
        columnStyles: {
            overflow: 'linebreak',
        },
    });

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/", "");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();

    addFooters(doc);
    doc.save(`${fileName}${today}${time}.pdf`);//
    this.$notify({
        group: 'container',
        text: this.$t('label.reportSuccessfully'),
        type: "success"
    });
}

async function generateExcel(rowData, titulo, excel, filters, colDelete, tableHeader, companyName, dataHeader) {
    
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(titulo);
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

    let y = 0;
    let letra = 12;
    let letterHeader = 12;
    let columnSize = 25;
    let columnsDefs = [];
    let keyColumns = [];
    let bandera = true;

    if (tableHeader) {
        
        columnsDefs = tableHeader.label;
        keyColumns = tableHeader.key;
        y = columnsDefs.length;
        bandera=false;
    }
    else 
        if (this.gridApi!==undefined) {
            columnsDefs = this.gridApi.getColumnDefs();
            this.columnApi.getColumnState().map(function (e) {
                if (typeof e.colId === 'string') {
                    keyColumns.push(e.colId);
                }
                y++;
            });
    } else {
        columnsDefs = Object.keys(rowData[0]);
        keyColumns = columnsDefs;
        y = columnsDefs.length;
        bandera=false;
    }

    if(colDelete)
    {
        keyColumns.splice(0, colDelete);
        columnsDefs.splice(0, colDelete);
        y = y-colDelete;
    }

    if (excel) {

        //***********************************OCK IMAGE****************************************** */
        if (y < 10) letra = 10;
        worksheet.mergeCells('A1:B3');
        const response1 = await this.$http.getFile('base64', {
            path:this.user.LogoRoute.replace('Public/','', null, 'i')
        });
        
        const divisiones1 = this.user.LogoRoute.split(".");
        const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
        const imageId = workbook.addImage({
            base64: myBase64Image,
            extension: divisiones1[1],
        });

        worksheet.addImage(imageId, 'A1:B3');

        const rowIndex1 = worksheet.getRow(1);
        rowIndex1.height = 25
        const rowIndex2 = worksheet.getRow(2);
        rowIndex2.height = 25
        const rowIndex3 = worksheet.getRow(3);
        rowIndex3.height = 25
        //*************************************************************************************** */

        let ColumnCont = 0;
        let containerHeader = dataHeader?.HeaderJson[0] ?? {};


       //-----------------------------PRINCIPALS HEADERS
        cells(`${companyName} `, 'C', 1, worksheet, false, false, true, false, columnSize, 30, "Calibri", 15, true, 'center', y > 10 ? numeroLetra(y) : numeroLetra(10), 3,'f2f2f2');
        cells(`${dataHeader?.label} `, 'A', 4, worksheet, false, false, true, false, columnSize, 30, "Calibri", letterHeader, true, 'center', y > 10 ? numeroLetra(y) : numeroLetra(10), 4, '44e5a70', 'ffffff');

        //-----------------------------HEADER 1
        ColumnCont += 1
        cells(`${this.$t('label.serialNumber')}: `,  numeroLetra(ColumnCont), 5, worksheet, false, true, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.SizeFt')}: `, numeroLetra(ColumnCont), 6, worksheet, false, true, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.status')}: `, numeroLetra(ColumnCont), 7, worksheet, false, true, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.carrier')}: `, numeroLetra(ColumnCont), 8, worksheet, false, true, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.isoCode')}: `, numeroLetra(ColumnCont), 9, worksheet, false, true, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.type')}: `, numeroLetra(ColumnCont), 10, worksheet, false, true, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.weight')}: `, numeroLetra(ColumnCont), 11, worksheet, false, true, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.confirmation')}: `, numeroLetra(ColumnCont), 12, worksheet, false, true, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');

        y > 10 ? ColumnCont += 2 : ColumnCont += 1
        
        cells(containerHeader?.ContainerCode ?? 'N/A', numeroLetra(y > 10 ? ColumnCont-1 : ColumnCont), 5, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y>10 ? numeroLetra(ColumnCont) : '', 5);
        cells(containerHeader?.Size ?? 'N/A', numeroLetra(y > 10 ? ColumnCont-1 : ColumnCont), 6, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y>10 ? numeroLetra(ColumnCont) : '', 6);
        cells(containerHeader?.Status ?? 'N/A', numeroLetra(y > 10 ? ColumnCont-1 : ColumnCont), 7, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y>10 ? numeroLetra(ColumnCont) : '', 7);
        cells(containerHeader?.ShippingLineCode ?? 'N/A', numeroLetra(y > 10 ? ColumnCont-1 : ColumnCont), 8, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y>10 ? numeroLetra(ColumnCont) : '', 8);
        cells(containerHeader?.IsoCode ?? 'N/A', numeroLetra(y > 10 ? ColumnCont-1 : ColumnCont), 9, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y>10 ? numeroLetra(ColumnCont) : '', 9);
        cells(`${(this.$i18n.locale=='en'?  `${containerHeader?.TpCargoNameEn} ${containerHeader?.TpCargoCode}` : `${containerHeader?.TpCargoNameEs} ${containerHeader?.TpCargoCode}`) }`, numeroLetra(y > 10 ? ColumnCont-1 : ColumnCont), 10, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y>10 ? numeroLetra(ColumnCont) : '', 10);
        cells(`${methodFormated(containerHeader?.Weigth)} ${containerHeader?.UnitMeasureWeigthAbbrev ?? ''}`, numeroLetra(y > 10 ? ColumnCont-1 : ColumnCont), 11, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y>10 ? numeroLetra(ColumnCont) : '', 11);
        cells(`${methodDate(containerHeader?.ConfirmationDate)}`, numeroLetra(y > 10 ? ColumnCont-1 : ColumnCont), 12, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y>10 ? numeroLetra(ColumnCont) : '', 12);
        
        //-----------------------------HEADER 2
        ColumnCont += 1
        cells(`${this.$t('label.vgm')}: `, numeroLetra(ColumnCont), 5, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.oversize')}: `, numeroLetra(ColumnCont), 6, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.reefer')}: `, numeroLetra(ColumnCont), 7, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.imdg')}: `, numeroLetra(ColumnCont), 8, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.damage')}: `, numeroLetra(ColumnCont), 9, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.Slot')}: `, numeroLetra(ColumnCont), 10, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.DirectDischarge')}: `, numeroLetra(ColumnCont), 11, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');

        y > 11 ? ColumnCont += 2 : ColumnCont += 1
        
        cells(`${methodFormated(containerHeader?.Vgm)} ${containerHeader?.UnitMeasureVgmAbbrev ?? ''}`, numeroLetra(y > 11 ? ColumnCont-1 : ColumnCont), 5, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 11 ? numeroLetra(ColumnCont) : '', 5);
        cells(containerHeader?.FgOverSize? this.$t('label.yes') : this.$t('label.no'), numeroLetra(y > 11 ? ColumnCont-1 : ColumnCont), 6, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 11 ? numeroLetra(ColumnCont) : '', 6);
        cells(containerHeader?.FgReefer? this.$t('label.yes') : this.$t('label.no'), numeroLetra(y > 11 ? ColumnCont-1 : ColumnCont), 7, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 11 ? numeroLetra(ColumnCont) : '', 7);
        cells(containerHeader?.FgImdg? containerHeader?.ImdgClassCode ?? containerHeader?.ImdgCode : this.$t('label.no'), numeroLetra(y > 11 ? ColumnCont-1 : ColumnCont), 8, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 11 ? numeroLetra(ColumnCont) : '', 8);
        cells(containerHeader?.FgDamaged? this.$t('label.yes'): this.$t('label.no'), numeroLetra(y > 11 ? ColumnCont-1 : ColumnCont), 9, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 11 ? numeroLetra(ColumnCont) : '', 9);
        cells(containerHeader?.BaySlot ?? 'N/A', numeroLetra(y > 11 ? ColumnCont-1 : ColumnCont), 10, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 11 ? numeroLetra(ColumnCont) : '', 10);
        cells(containerHeader?.FgDirect ? this.$t('label.yes'): this.$t('label.no'), numeroLetra(y > 11 ? ColumnCont-1 : ColumnCont), 11, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 11 ? numeroLetra(ColumnCont) : '', 11);
        
        //-----------------------------HEADER 3
        ColumnCont += 1
        cells(`${this.$t('POL')}: `, numeroLetra(ColumnCont), 5, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('POD')}: `, numeroLetra(ColumnCont), 6, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.seals')} 1: `, numeroLetra(ColumnCont), 7, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.seals')} 2: `, numeroLetra(ColumnCont), 8, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.seals')} 3: `, numeroLetra(ColumnCont), 9, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.seals')} 4: `, numeroLetra(ColumnCont), 10, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');

        y > 12 ? ColumnCont += 2 : ColumnCont += 1
        cells(containerHeader?.LoadPort ?? 'N/A', numeroLetra(y > 12 ? ColumnCont-1 : ColumnCont), 5, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 11 ? numeroLetra(ColumnCont) : '', 5);
        cells(containerHeader?.DischargePort ?? 'N/A', numeroLetra(y > 12 ? ColumnCont-1 : ColumnCont), 6, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 11 ? numeroLetra(ColumnCont) : '', 6);
        cells(containerHeader?.Seal1 ?? 'N/A', numeroLetra(y > 12 ? ColumnCont-1 : ColumnCont), 7, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 11 ? numeroLetra(ColumnCont) : '', 7);
        cells(containerHeader?.Seal2 ?? 'N/A', numeroLetra(y > 12 ? ColumnCont-1 : ColumnCont), 8, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 11 ? numeroLetra(ColumnCont) : '', 8);
        cells(containerHeader?.Seal3 ?? 'N/A', numeroLetra(y > 12 ? ColumnCont-1 : ColumnCont), 9, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 11 ? numeroLetra(ColumnCont) : '', 9);
        cells(containerHeader?.Seal4 ?? 'N/A', numeroLetra(y > 12 ? ColumnCont-1 : ColumnCont), 10, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 11 ? numeroLetra(ColumnCont) : '', 10);


        //-----------------------------HEADER 4
        ColumnCont += 1
        cells(`${this.$t('label.status')}: `, numeroLetra(ColumnCont), 5, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.yard')}: `, numeroLetra(ColumnCont), 6, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.yardPosition')}: `, numeroLetra(ColumnCont), 7, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.daysInYard')}: `, numeroLetra(ColumnCont), 8, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.receptionDate')}: `, numeroLetra(ColumnCont), 9, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.dispatchDate')}: `, numeroLetra(ColumnCont), 10, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');

        if(dataHeader?.BlJson?.length == 1){
            cells(`${this.$t('label.customBroker')}: `, numeroLetra(ColumnCont), 11, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
            cells(`${this.$t('label.consignee')}: `, numeroLetra(ColumnCont), 12, worksheet, false, false, false, true, columnSize, 18, "Calibri", letterHeader, true, 'right');
        }

        y > 13 ? ColumnCont += 2 : ColumnCont += 1
        cells(this.$i18n.locale=='en'?  containerHeader?.YardCargoStatusDsEn : containerHeader?.YardCargoStatusDsEs, numeroLetra(y > 13 ? ColumnCont-1 : ColumnCont), 5, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 13 ? numeroLetra(ColumnCont) : '', 5);
        cells(containerHeader?.YardName ?? 'N/A', numeroLetra(y > 13 ? ColumnCont-1 : ColumnCont), 6, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 13 ? numeroLetra(ColumnCont) : '', 6);
        cells(containerHeader?.YardSlotCode ?? 'N/A', numeroLetra(y > 13 ? ColumnCont-1 : ColumnCont), 7, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 13 ? numeroLetra(ColumnCont) : '', 7);
        cells(this.$i18n.locale=='en'?  containerHeader?.DaysInYardEn : containerHeader?.DaysInYardEs, numeroLetra(y > 13 ? ColumnCont-1 : ColumnCont), 8, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 13 ? numeroLetra(ColumnCont) : '', 8);
        cells(methodDate(containerHeader?.ReceptionDate), numeroLetra(y > 13 ? ColumnCont-1 : ColumnCont), 9, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 13 ? numeroLetra(ColumnCont) : '', 9);
        cells(methodDate(containerHeader?.DispatchDate), numeroLetra(y > 13 ? ColumnCont-1 : ColumnCont), 10, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 13 ? numeroLetra(ColumnCont) : '', 10);
        
        if(dataHeader?.BlJson?.length == 1){
            cells(containerHeader?.ClientNameCustomBroker ?? 'N/A', numeroLetra(y > 13 ? ColumnCont-1 : ColumnCont),11, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 13 ? numeroLetra(ColumnCont) : '', 11);
            cells(containerHeader?.ClientNameConsignee ?? 'N/A', numeroLetra(y > 13 ? ColumnCont-1 : ColumnCont), 12, worksheet, false, false, false, true, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 13 ? numeroLetra(ColumnCont) : '', 12);

        }
            

        //-----------------------------HEADER 5
        ColumnCont += 1
        cells(`${this.$t('label.portActivity')}: `, numeroLetra(ColumnCont), 5, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.Seal')} 1: `, numeroLetra(ColumnCont), dataHeader?.BlJson?.length == 1? 8 : 6, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.Seal')} 2: `, numeroLetra(ColumnCont), dataHeader?.BlJson?.length == 1? 9 : 7, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.Seal')} 3: `, numeroLetra(ColumnCont), dataHeader?.BlJson?.length == 1? 10 : 8, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        cells(`${this.$t('label.Seal')} 4: `, numeroLetra(ColumnCont), dataHeader?.BlJson?.length == 1? 11 : 9, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
        
        if(dataHeader?.BlJson?.length == 1){
            cells(`${this.$t('label.bl')}: `, numeroLetra(ColumnCont), 6, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
            cells(`${this.$t('label.Mode')}: `, numeroLetra(ColumnCont), 7, worksheet, false, false, false, false, columnSize, 18, "Calibri", letterHeader, true, 'right');
            cells(`${this.$t('label.inventoryContains')}: `, numeroLetra(ColumnCont), 12, worksheet, false, false, false, true, columnSize, 18, "Calibri", letterHeader, true, 'right');
        }  


        y > 14 ? ColumnCont += 2 : ColumnCont += 1
        cells(this.$i18n.locale=='en'?  containerHeader?.PortActivityNameEn : containerHeader?.PortActivityNameEs, numeroLetra(y > 14 ? ColumnCont-1 : ColumnCont), 5, worksheet, false, false, true, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 14 ? numeroLetra(ColumnCont) : '', 5);
        cells(containerHeader?.YardSeal1 ?? 'N/A', numeroLetra(y > 14 ? ColumnCont-1 : ColumnCont), dataHeader?.BlJson?.length == 1? 8 : 6, worksheet, false, false, true, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 14 ? numeroLetra(ColumnCont) : '', dataHeader?.BlJson?.length == 1? 8 : 6);
        cells(containerHeader?.YardSeal2 ?? 'N/A', numeroLetra(y > 14 ? ColumnCont-1 : ColumnCont), dataHeader?.BlJson?.length == 1? 9 : 7, worksheet, false, false, true, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 14 ? numeroLetra(ColumnCont) : '', dataHeader?.BlJson?.length == 1? 9 : 7);
        cells(containerHeader?.YardSeal3 ?? 'N/A', numeroLetra(y > 14 ? ColumnCont-1 : ColumnCont), dataHeader?.BlJson?.length == 1? 10 : 8, worksheet, false, false, true, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 14 ? numeroLetra(ColumnCont) : '', dataHeader?.BlJson?.length == 1? 10 : 8);
        cells(containerHeader?.YardSeal4 ?? 'N/A', numeroLetra(y > 14 ? ColumnCont-1 : ColumnCont), dataHeader?.BlJson?.length == 1? 11 : 9, worksheet, false, false, true, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 14 ? numeroLetra(ColumnCont) : '', dataHeader?.BlJson?.length == 1? 11 : 9);
        
        if(dataHeader?.BlJson?.length == 1){
            cells(containerHeader?.BlNro ?? 'N/A', numeroLetra(y > 14 ? ColumnCont-1 : ColumnCont), 6, worksheet, false, false, true, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 14 ? numeroLetra(ColumnCont) : '', 6);
            cells(containerHeader?.TpCargoConditionCode ?? 'N/A', numeroLetra(y > 14 ? ColumnCont-1 : ColumnCont), 7, worksheet, false, false, true, false, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 14 ? numeroLetra(ColumnCont) : '', 7);
            cells(containerHeader?.DescriptionOfGoods ?? 'N/A', numeroLetra(y > 14 ? ColumnCont-1 : ColumnCont), 12, worksheet, false, false, true, true, columnSize, 18, "Calibri", letterHeader, false, 'start', y > 14 ? numeroLetra(ColumnCont) : '', 12);
        }
        else{
            cells(``, numeroLetra(y > 14 ? ColumnCont-1 : ColumnCont), 10, worksheet, false, false, true, false, columnSize, 0, "Calibri", 18, false);
            cells(``, numeroLetra(y > 14 ? ColumnCont-1 : ColumnCont), 11, worksheet, false, false, true, false, columnSize, 0, "Calibri", 18, false);
            cells(``, numeroLetra(y > 14 ? ColumnCont-1 : ColumnCont), 12, worksheet, false, false, true, false, columnSize, 0, "Calibri", 18, false);
        }
            
        //---- END CELLS
        cells(``, 'A', 13, worksheet, true, false, false, false, columnSize, 0, "Calibri", 18, false, 'start', numeroLetra(ColumnCont), 13);
        //----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------


        

        //division de la fila
        let division = Math.floor(ColumnCont / columnsDefs.length);
        let Rango = [];
        let restante = 0;
        let Extra = (ColumnCont-(division*columnsDefs.length));
        for (let index = 0 , Num = 1; index < columnsDefs.length; index++, Num++) {
            if (index < (columnsDefs.length-1)) {
                Rango.push({
                    rango1: (division*Num),
                    rango11: ((division*Num) +1),
                }) 
            }else{
                restante = ColumnCont - (division*Num) - 1;
                Rango.push({
                    rango1: (ColumnCont),
                })
            }
        }

        
        //UNIFICACIÓN DE COLUMNAS RESTANTES
        Rango.map(function(e, index){      
            e.rango1 = numeroLetra(e.rango1);
            e.rango11 = numeroLetra(e.rango11);
        })


       //rango de la filas
        cells(columnsDefs[0], 'A',14, worksheet, true, true, true, true, columnSize, 30, "Calibri", letra, true, 'center',Rango[0].rango1, 14, 'cccccc', '');

        for (let index = 0; index < (Rango.length-1); index++) {
            cells(columnsDefs[index+1], Rango[index].rango11,14, worksheet, true, true, true, true, columnSize, 30, "Calibri", letra, true, 'center',Rango[index+1].rango1, 14, 'cccccc', ''); 
        }

        //DATA DINAMICAS
        let numRow = 14;

        rowData.map(async function(data){
            numRow+=1;

            cells(data[keyColumns[0]], 'A',numRow, worksheet, true, true, true, true, columnSize, 30, "Calibri", letra, true, 'center',Rango[0].rango1, numRow);

            for (let index = 0; index < (Rango.length-1); index++) {
                cells(data[keyColumns[index+1]], Rango[index].rango11,numRow, worksheet, true, true, true, true, columnSize, 30, "Calibri", letra, false, 'center',Rango[index+1].rango1, numRow); 
            }
        })


    }

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/","");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
    //return false;
    if (excel) {
        workbook.xlsx.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    } else {
        workbook.csv.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.csv`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    }
}


export default {
  data,
  methods: {
    getPdf,
    getPdfArray,
    generateExcel,
    numeroLetra,
    methodFormated,
    methodDate
  },
  computed:{
    cells,
    ...mapState({
        Visit: state => state.visitas.VisitId,
        dropItemReport: state => state.visitas.dropItemReport,       
        ItineraryId: state => state.visitas.ItineraryId,
        itinerarySelected: state => state.visitas.itinerarySelected,
        user: state => state.auth.user,
        branch: state => state.auth.branch,
        apiStateForm: state => state.visitas.apiStateForm,

    }),
  },
}