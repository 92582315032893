<template>
  <form>
    <CModalExtended
      :title="`${$t('label.bl')}: ${dataBasic.bl}`"
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
      size="xl"
    >
       
      <CustomTabs :active-tab="tabIndex" @update:activeTab="handleTab">
        <CustomTab>
          <template #title>
            <span>{{$t('label.basicData')}}</span>
          </template>
          <CRow class="justify-content-center mt-3" >
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.bl')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="dataBasic.bl" 
                :disabled="true"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.nBooking')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="dataBasic.nBooking" 
                :disabled="true"
              >
              </CInput>
            </CCol>
          </CRow>

          <CRow class="justify-content-center">
            <CCol sm="12" lg="5">
              <CRow class="justify-content-center px-3">
                <CCol sm="12" lg="4" class="px-2">
                  <label class="col-form-label-sm col-sm-12 text-right px-0">{{`${$t('label.weight')}(KGS)`}}</label>
                </CCol>
                <CCol sm="12" lg="8" class="px-0">
                  <money
                    size="sm"
                    v-model.trim="dataBasic.weight"
                    v-bind="measure" 
                    class="form-control col-sm-12"
                    style="max-height: 80%;"
                    :disabled="true"
                  ></money>
                </CCol>
              </CRow>
            </CCol>
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.Origin')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8 '}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="dataBasic.origin" 
                :disabled="true"
              >
              </CInput>
            </CCol>
          </CRow>

          <CRow class="justify-content-center">
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="`${$t('label.volume')}(M³)`"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="dataBasic.volume" 
                :disabled="true"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.quantity')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="dataBasic.amount" 
                :disabled="true"
              >
              </CInput>
            </CCol>
          </CRow>
          
          
          <CRow class="justify-content-center">
            <CCol sm="12" lg="5">
              <div class="position-relative">
                <div class="form-group form-row">
                  <label class="col-form-label col-sm-12 col-lg-4 col-form-label-sm text-right">
                    {{$t('label.heading')}} 
                  </label>
                  <div class="col-sm-12 col-lg-8 px-1">
                    <multiselect 
                      size="sm"
                      v-model="dataBasic.headingJson"
                      :options="rubroOptions"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :placeholder="$t('label.select')"
                      :label="this.$i18n.locale=='en'? 'HeadingNameEn' : 'HeadingNameEs'"
                      track-by="HeadingId"
                      class="col-sm-12 p-0"
                      :disabled="true"
                      
                    >
                    </multiselect>
                  </div>
                </div>
              </div>
            </CCol>
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.customsRegime')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="dataBasic.customsRegime" 
                :disabled="true"
              >
              </CInput>
            </CCol>
          </CRow>

          <CRow class="justify-content-center">
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="`${$t('label.bolipuertoReceptionRecordNro')}`"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="dataBasic.bolipuertoReceptionRecordNro" 
                :disabled="true"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="`${$t('label.nDua')}`"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="dataBasic.nDua" 
                :disabled="true"
              >
              </CInput>
            </CCol>
          </CRow>
        </CustomTab>
        <CustomTab>
          <template #title>
            <span>{{$t('label.imdg')}}</span>
          </template>
          <dataTableExtended
              class="align-center-row-datatable"
              :items="computedListImdg"
              :fields="imdgfields"
              :items-per-page="5"
              column-filter
              pagination
              :table-filter="tableText.tableFilterText"
              :items-per-page-select="tableText.itemsPerPageText"
              :noItemsView="tableText.noItemsViewText"
              sorter
            >
     
              <template #Status="{ item }">
              <td class="text-center">
              <CBadge :color="getBadge(item.Status)">
                {{ $t('label.'+item.Status) }}
              </CBadge>
              </td>
              </template>
              <template #options="{ item }">
                <td class="text-center">
              
                    <CButton
                      square
                      size="sm"
                      color="excel"
                      class="d-flex align-items-center"
                      @click="toggleContainerEdit(item)"
                      v-c-tooltip="{
                        content: $t('label.edit')+' '+$t('label.eirTransaction'),
                        placement: 'top-end'
                      }"
                      >
                      <CIcon name="cil-cloud-download" />
                    </CButton>
                
                </td>
              </template>
            </dataTableExtended>
        </CustomTab>
        <CustomTab>
          <template #title>
            <span>{{$t('label.seller')}}</span>
          </template>
          <CRow class="justify-content-center mt-3">
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.seller')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.seller.sellerName"
                :disabled="true"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.address')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.seller.sellerAddress"
                :disabled="true"
              >
              </CInput>
            </CCol>
          </CRow>
          <CRow class="justify-content-center">
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.mainPhone')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.seller.sellerPrimaryPhone"
                :disabled="true"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="5" >
              <CInput
                size="sm"
                :label="$t('label.secondaryPhone')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.seller.SellerSecondaryPhone"
                :disabled="true"
              >
              </CInput>
            </CCol>
          </CRow>
          <CRow class="justify-content-center">
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.mainEmail')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.seller.sellerPrimaryEmail"
                :disabled="true"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.secondaryEmail')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.seller.sellerSecondaryEmail"
                :disabled="true"
              >
              </CInput>
            </CCol>
          </CRow>
        </CustomTab>
        <CustomTab>
          <template #title>
            <span>{{$t('label.customBroker')}}</span>
          </template>

          <CRow class="justify-content-center mt-3" v-show="this.consolidatorCheck">
            <CCol sm="12" lg="auto">
              <b>
                <label class="col-form-label-sm col-sm-12 col-lg-12 text-right px-1">{{$t('label.customBroker')}}</label>
              </b>
            </CCol>
            <CCol sm="12" lg="8"></CCol>
          </CRow>

          <CRow class="justify-content-center mt-3">
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.rifNumber')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.customBroke.CustomBrokerRif"
                :disabled="true"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="5"></CCol>
          </CRow>
          <CRow class="justify-content-center">
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.customBroker')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.customBroke.CustomBrokerName"
                :disabled="true"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.address')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.customBroke.CustomBrokerAddress"
                :disabled="true"
              >
              </CInput>
            </CCol>
          </CRow>
          <CRow class="justify-content-center">
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.mainPhone')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.customBroke.CustomBrokerPrimaryPhone"
                :disabled="true"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="5" >
              <CInput
                size="sm"
                :label="$t('label.secondaryPhone')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.customBroke.CustomBrokerSecondaryPhone"
                :disabled="true"
              >
              </CInput>
            </CCol>
          </CRow>
          <CRow class="justify-content-center">
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.mainEmail')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.customBroke.CustomBrokerPrimaryEmail"
                :disabled="true"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.secondaryEmail')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.customBroke.CustomBrokerSecondaryEmail"
                :disabled="true"
              >
              </CInput>
            </CCol>
          </CRow>

          <CRow class="justify-content-center" >
            <CCol sm="12" lg="auto">
                <label class="col-form-label-sm col-sm-12 col-lg-12 text-right px-1">{{$t('label.itTheConsolidator')}}</label>
            </CCol>
            <CCol sm="12" lg="8">
              <div class="form-group row">
                  <label class="col-sm-6 col-lg-auto text-right">{{ `${this.$t('label.no')}`}}</label>
                  <div class="col-sm-6 col-lg-auto d-flex align-items-center px-0">
                      <CSwitch
                          color="watch"
                          variant="3d"
                          type="checkbox"
                          :checked.sync="consolidatorCheck"
                          :disabled="true"
                      />
                  </div>
                  <label class="col-sm-6 col-lg-auto text-right">{{ `${this.$t('label.yes')}`}}</label>
              </div>
            </CCol>
          </CRow>

          <CRow class="justify-content-center mt-3" v-show="this.consolidatorCheck">
            <CCol sm="12" lg="auto">
              <b>
                <label class="col-form-label-sm col-sm-12 col-lg-12 text-right px-1">{{$t('label.consolidator')}}</label>
              </b>
            </CCol>
            <CCol sm="12" lg="8"></CCol>
          </CRow>

          <CRow class="justify-content-center mt-3" v-show="this.consolidatorCheck">
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.rifNumber')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.consolidator.ConsolidatorRif"
                :disabled="true"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="5"></CCol>
          </CRow>
          <CRow class="justify-content-center" v-show="this.consolidatorCheck">
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.consolidator')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.consolidator.ConsolidatorName"
                :disabled="true"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.address')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.consolidator.ConsolidatorAddress"
                :disabled="true"
              >
              </CInput>
            </CCol>
          </CRow>
          <CRow class="justify-content-center" v-show="this.consolidatorCheck">
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.mainPhone')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.consolidator.ConsolidatorPrimaryPhone"
                :disabled="true"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="5" >
              <CInput
                size="sm"
                :label="$t('label.secondaryPhone')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.consolidator.ConsolidatorSecondaryPhone"
                :disabled="true"
              >
              </CInput>
            </CCol>
          </CRow>
          <CRow class="justify-content-center" v-show="this.consolidatorCheck">
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.mainEmail')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.consolidator.ConsolidatorPrimaryEmail"
                :disabled="true"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.secondaryEmail')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.consolidator.ConsolidatorSecondaryEmail"
                :disabled="true"
              >
              </CInput>
            </CCol>
          </CRow>
        </CustomTab>
        <CustomTab>
          <template #title>
            <span>{{$t('label.consignee')}}</span>
          </template>
          <CRow class="justify-content-center mt-3">
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.rifNumber')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.consignee.consigneeRif"
                :disabled="true"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="5"></CCol>
          </CRow>
          <CRow class="justify-content-center">
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.consignee')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.consignee.consigneeName"
                :disabled="true"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="5">
              <CInput
                size="sm" 
                :label="$t('label.address')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.consignee.consigneeAddress"
                :disabled="true"
              >
              </CInput>
            </CCol>
          </CRow>
          <CRow class="justify-content-center">
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.mainPhone')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.consignee.consigneePrimaryPhone"
                :disabled="true"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.secondaryPhone')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.consignee.consigneeSecondaryPhone"
                :disabled="true"
              >
              </CInput>
            </CCol>
          </CRow>
          <CRow class="justify-content-center">
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.mainEmail')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.consignee.consigneePrimaryEmail"
                :disabled="true"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.secondaryEmail')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.consignee.consigneeSecondaryEmail"
                :disabled="true"
              >
              </CInput>
            </CCol>
          </CRow>
        </CustomTab>
        <CustomTab>
          <template #title>
            <span>{{$t('label.notifyParty')}}</span>
          </template>
          <CRow class="mt-3 justify-content-center">
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.notifyParty')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.notify.notifyToName"
                :disabled="true"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.address')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.notify.notifyToAddress"
                :disabled="true"
              >
              </CInput>
            </CCol>
          </CRow>
          <CRow class="justify-content-center">
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.mainPhone')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.notify.notifyToPrimaryPhone"
                :disabled="true"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.secondaryPhone')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.notify.notifyToSecondaryPhone"
                :disabled="true"
              >
              </CInput>
            </CCol>
          </CRow>
          <CRow class="justify-content-center">
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.mainEmail')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.notify.notifyToPrimaryEmail"
                :disabled="true"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="5">
              <CInput
                size="sm"
                :label="$t('label.secondaryEmail')"
                :horizontal="{label:'col-sm-12 col-lg-4 p-0 ', input:'col-sm-12 col-lg-8'}"
                :placeholder="''"
                addLabelClasses="text-right"
                v-model="this.notify.notifyToSecondaryEmail"
                :disabled="true"
              >
              </CInput>
            </CCol>
          </CRow>
        </CustomTab>
        <CustomTab>
          <template #title>
            <span>{{$t('label.inventoryContains')}}</span>
          </template>
          <CRow>
            <CCol sm="12" lg="12">
                <CCol sm="12">
                    <vue-editor
                      size="sm"
                      :horizontal="{label:'col-sm-12 col-lg-12', input:'col-sm-12 col-lg-12'}"
                      v-model.trim="featuresDescription"
                      :disabled="true"
                    >
                    </vue-editor>
                </CCol>
              </CCol>
            </CRow>
        </CustomTab>
        <CustomTab>
          <template #title>
            <span>{{$t('label.documentation')}}</span>
          </template>
          <dataTableExtended
              class="align-center-row-datatable"
              :items="computedDocumentContainer"
              :fields="documentfields"
              :items-per-page="5"
              column-filter
              pagination
              :table-filter="tableText.tableFilterText"
              :items-per-page-select="tableText.itemsPerPageText"
              :noItemsView="tableText.noItemsViewText"
              sorter
            >
     
              <template #Status="{ item }">
              <td class="text-center">
              <CBadge :color="getBadge(item.Status)">
                {{ $t('label.'+item.Status) }}
              </CBadge>
              </td>
              </template>
              <template #options="{ item }">
                <td class="text-center">
              
                    <CButton
                      square
                      size="sm"
                      color="excel"
                      class="d-flex align-items-center"
                      @click="downloadArchive(item)"
                      v-c-tooltip="{
                        content: $t('label.download'),
                        placement: 'top-end'
                      }"
                      >
                      <CIcon name="cil-cloud-download" />
                    </CButton>
                
                </td>
              </template>
            </dataTableExtended>
        </CustomTab>
      </CustomTabs>

      <template #footer>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="toggle(false)"
        >
          <CIcon name="x" /><span class="ml-1"
            >{{$t('label.exit')}}</span
          >
        </CButton>
      </template>

    </CModalExtended>
  </form>
</template>
<script>
  import ModalMixin from '@/_mixins/modal';
  import GeneralMixin from '@/_mixins/general';
  import UpperCase from '@/_validations/uppercase-directive';
  import CustomTabs from '@/components/tabs/CustomTabs';
  import CustomTab from '@/components/tabs/CustomTab';
  import { VueEditor } from "vue2-editor";
  import { mapState } from 'vuex';
  import { DateFormater } from '@/_helpers/funciones';
  import DataStorage from '@/_helpers/localStorage';

function data() {
  return {
    modalActive: false,
    featuresDescription:'',
    tabIndex: 0,
    consolidatorCheck: false,
    dataBasic:{
      bl: '',
      nBooking: '',
      weight: '',
      origin: '',
      volume: '',
      amount: '',
      headingJson: {},
      customsRegime:'',
      bolipuertoReceptionRecordNro: '',
      nDua: '',
    },
    seller:{
      sellerName: '',
      sellerAddress: '',
      sellerPrimaryPhone: '',
      sellerSecondaryEmail: '',
      sellerPrimaryEmail: '',
      sellerSecondaryEmail: ''
    },
    consignee:{
      consigneeRif: '',
      consigneeName: '',
      consigneeAddress: '',
      consigneePrimaryPhone: '',
      consigneeSecondaryPhone: '',
      consigneePrimaryEmail: '',
      consigneeSecondaryEmail: ''
    },
    notify:{
      notifyToName: '',
      notifyToAddress: '',
      notifyToPrimaryPhone: '',
      notifyToSecondaryPhone: '',
      notifyToPrimaryEmail: '',
      notifyToSecondaryEmail: ''
    },
    customBroke:{
      CustomBrokerRif: '',
      CustomBrokerName: '',
      CustomBrokerAddress: '',
      CustomBrokerPrimaryPhone: '',
      CustomBrokerSecondaryPhone: '',
      CustomBrokerPrimaryEmail: '',
      CustomBrokerSecondaryEmail: '',
    },
    consolidator:{
      ConsolidatorRif: '',
      ConsolidatorName: '',
      ConsolidatorAddress: '',
      ConsolidatorPrimaryPhone: '',
      ConsolidatorSecondaryPhone: '',
      ConsolidatorPrimaryEmail: '',
      ConsolidatorSecondaryEmail: ''
    },
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
      maxlength: 18,
    },
  };
}

//methods
function toggle(newVal) {
  this.modalActive = newVal;
}

function handleTab(tab) {
  this.tabIndex = tab;
}



//Computeds:
function documentfields(){
  return [
    { key: 'RowNumber', label: '#', _style: 'width:19%; text-align:center', _classes: 'text-center', filter: false },
    { label: this.$t('label.documents'), key: 'BlCargoDocumentDs', _classes: 'text-uppercase text-center', _style: 'width:19%;', sorter: true, filter: true},
    { key: 'TransaUSerLogin', label: this.$t('label.user'),  _classes: 'text-uppercase text-center', _style: 'width:19%;', sorter: true, filter: true},
    { key: 'TransaRegDate', label: this.$t('label.date'), _style:'width:19%;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
    { key: 'Status', label: this.$t('label.status'), _style:'width:19%;', _classes:"center-cell text-center", sorter: true, filter: true},
    { key: 'options', label: '', _style: 'width:4%; min-width:20px;', sorter: false, filter: false },
  ];
}

function imdgfields(){
  return [
    { key: 'RowNumber', label: '#', _style: 'width:5%; text-align:center', _classes: 'text-center', filter: false },
    { label: this.$t('label.imdgCode'), key: 'ImdgCode', _classes: 'text-uppercase text-center', _style: 'width:15%;', sorter: true, filter: true},
    { key: 'ImdgClassCode', label: this.$t('label.hazardClass'),  _classes: 'text-uppercase text-center', _style: 'width:19%;', sorter: true, filter: true},
    { key: 'PackagingGroupId', label: this.$t('label.PackagingGroup'), _style:'width:19%;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
    { key: 'UnNumber', label: this.$t('label.unNumber'), _style:'width:12%;', _classes:"center-cell text-center", sorter: true, filter: true},
    { key: 'DescriptionOfGoodsDGS', label: this.$t('label.description'), _style:'width:30%;', _classes:"center-cell text-center", sorter: true, filter: true},
  ];
}

function rubroOptions() {
  return [];
}

function downloadArchive(item) {
  let route = item.RouteBlCargoDocument.toLowerCase();
  
  if(route.includes("public/"))
      return window.open( (`${this.$store.getters['connection/getBase']}${route.split("public/").pop()}`).toLowerCase(), '_blank');
  else
      return window.open( (`${this.$store.getters['connection/getBase']}${route}`).toLowerCase(), '_blank');
}



function computedDocumentContainer() {
  if(this.ContainerItem.DocumentJson )
    return   this.ContainerItem.DocumentJson.map((item) => Object.assign({}, item, {
              RowNumber: item.IdX,
              BlCargoDocumentDs: item.BlCargoDocumentDs ?? '',
              TransaUSerLogin: item.TransaUSerLogin ?? '',
              TransaRegDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
              Status: item.Status ?? '',
          }));
  else
    return []
}

function computedListImdg() {
  if(this.ContainerItem.ImdgJson)
    return   this.ContainerItem.ImdgJson.map((item) => Object.assign({}, item, {
              RowNumber: item.IdX,
              ImdgCode: item.ImdgCode ?? '',
              ImdgClassCode: item.ImdgClassCode ?? '',
              PackagingGroupId: item.PackagingGroupCode ?? '',
              UnNumber: item.UnNumber ?? '',
              DescriptionOfGoodsDGS: item.DescriptionOfGoodsDGS ?? '',
          }));
  else
    return []
}


export default {
  name: 'modal-bl',
  components: {
    CustomTabs,
    CustomTab,
    VueEditor
   },
  props: { modal: Boolean, ContainerItem: Object },
  data,
  mixins: [
    ModalMixin,
    GeneralMixin
  ],
  directives: UpperCase,
  methods: {
    handleTab,
    toggle,
    downloadArchive
  },
  computed: {
    rubroOptions,
    computedDocumentContainer,
    computedListImdg,
    documentfields,
    imdgfields,
    ...mapState({
      dataContainer: state => state.containerSearch.dataContainer,
    })

  },
  watch: {
    modal: function(val){
      this.tabIndex = 0;
      this.modalActive = val;
    },

    ContainerItem: function (newValue) {

      if(Object.keys(newValue).length > 0){

        if(newValue?.CustomBrokerClientId != newValue?.ConsolidatorClientId){
          this.consolidatorCheck = true;
        }

        this.featuresDescription = newValue?.DescriptionOfGoods;
      }


      if(newValue?.BasicDataJson[0] && Object.keys(newValue?.BasicDataJson[0]).length > 0){
        this.dataBasic.bl = newValue?.BasicDataJson[0].BlNro
        this.dataBasic.nBooking = newValue?.BasicDataJson[0].BookingNro
        this.dataBasic.weight = newValue?.BasicDataJson[0].Weigth
        this.dataBasic.origin = newValue?.BasicDataJson[0].Procedence;
        this.dataBasic.volume = newValue?.BasicDataJson[0].Volumen
        this.dataBasic.amount = newValue?.BasicDataJson[0].Quantity
        this.dataBasic.headingJson = newValue?.BasicDataJson[0].HeadingJson;
        this.dataBasic.customsRegime = newValue?.CustomsRegime;
        this.dataBasic.bolipuertoReceptionRecordNro = newValue?.ReceptionBpAct;
        this.dataBasic.nDua = newValue?.Dua;
      }
      if(newValue?.SellerJson[0] && Object.keys(newValue?.SellerJson[0]).length > 0){
        this.seller.sellerName = newValue?.SellerJson[0].SellerName
        this.seller.sellerAddress = newValue?.SellerJson[0].SellerAddress
        this.seller.sellerPrimaryPhone = newValue?.SellerJson[0].SellerPrimaryPhone
        this.seller.sellerSecondaryEmail = newValue?.SellerJson[0].SellerSecondaryEmail;
        this.seller.sellerPrimaryEmail = newValue?.SellerJson[0].SellerPrimaryEmail
        this.seller.SellerSecondaryPhone = newValue?.SellerJson[0].SellerSecondaryPhone
      }
      if(newValue?.ConsigneeJson[0] && Object.keys(newValue?.ConsigneeJson[0]).length > 0){
        this.consignee.consigneeRif = newValue?.ConsigneeJson[0].ConsigneeRif
        this.consignee.consigneeName = newValue?.ConsigneeJson[0].ConsigneeName
        this.consignee.consigneeAddress = newValue?.ConsigneeJson[0].ConsigneeAddress
        this.consignee.consigneePrimaryPhone = newValue?.ConsigneeJson[0].ConsigneePrimaryPhone
        this.consignee.consigneeSecondaryPhone = newValue?.ConsigneeJson[0].ConsigneeSecondaryPhone;
        this.consignee.consigneePrimaryEmail = newValue?.ConsigneeJson[0].ConsigneePrimaryEmail
        this.consignee.consigneeSecondaryEmail = newValue?.ConsigneeJson[0].ConsigneeSecondaryEmail
      }
      
      if(newValue?.NotifyToJson[0] && Object.keys(newValue?.NotifyToJson[0]).length > 0){
        this.notify.notifyToName = newValue?.NotifyToJson[0].NotifyToName
        this.notify.notifyToAddress = newValue?.NotifyToJson[0].NotifyToAddress
        this.notify.notifyToPrimaryPhone = newValue?.NotifyToJson[0].NotifyToPrimaryPhone
        this.notify.notifyToSecondaryPhone = newValue?.NotifyToJson[0].NotifyToSecondaryPhone;
        this.notify.notifyToPrimaryEmail = newValue?.NotifyToJson[0].NotifyToPrimaryEmail
        this.notify.notifyToSecondaryEmail = newValue?.NotifyToJson[0].NotifyToSecondaryEmail
      }

      if(newValue?.CustomBrokerJson[0] && Object.keys(newValue?.CustomBrokerJson[0]).length > 0){
        this.customBroke.CustomBrokerRif = newValue?.CustomBrokerJson[0].CustomBrokerRif
        this.customBroke.CustomBrokerName = newValue?.CustomBrokerJson[0].CustomBrokerName
        this.customBroke.CustomBrokerAddress = newValue?.CustomBrokerJson[0].CustomBrokerAddress
        this.customBroke.CustomBrokerPrimaryPhone = newValue?.CustomBrokerJson[0].CustomBrokerPrimaryPhone;
        this.customBroke.CustomBrokerSecondaryPhone = newValue?.CustomBrokerJson[0].CustomBrokerSecondaryPhone
        this.customBroke.CustomBrokerPrimaryEmail = newValue?.CustomBrokerJson[0].CustomBrokerPrimaryEmail
        this.customBroke.CustomBrokerSecondaryEmail = newValue?.CustomBrokerJson[0].CustomBrokerSecondaryEmail
      }

      if(newValue?.ConsolidatorJson[0] && Object.keys(newValue?.ConsolidatorJson[0]).length > 0){
        this.consolidator.ConsolidatorRif = newValue?.ConsolidatorJson[0].ConsolidatorRif
        this.consolidator.ConsolidatorName = newValue?.ConsolidatorJson[0].ConsolidatorName
        this.consolidator.ConsolidatorAddress = newValue?.ConsolidatorJson[0].ConsolidatorAddress
        this.consolidator.ConsolidatorPrimaryPhone = newValue?.ConsolidatorJson[0].ConsolidatorPrimaryPhone;
        this.consolidator.ConsolidatorSecondaryPhone = newValue?.ConsolidatorJson[0].ConsolidatorSecondaryPhone
        this.consolidator.ConsolidatorPrimaryEmail = newValue?.ConsolidatorJson[0].ConsolidatorPrimaryEmail
        this.consolidator.ConsolidatorSecondaryEmail = newValue?.ConsolidatorJson[0].ConsolidatorSecondaryEmail
      }
    },

    dataContainer: function (newValue) {
        if (Object.keys(newValue).length == 0){
          this.dataBasic.bl = '';
          this.dataBasic.nBooking = '';
          this.dataBasic.weight = '';
          this.dataBasic.origin = '';
          this.dataBasic.volume = '';
          this.dataBasic.amount = '';
          this.dataBasic.headingJson = '';
          this.dataBasic.customsRegime = '';
          this.dataBasic.bolipuertoReceptionRecordNro = '';
          this.dataBasic.nDua = '';
          

          this.seller.sellerName = '';
          this.seller.sellerAddress = '';
          this.seller.sellerPrimaryPhone = '';
          this.seller.sellerSecondaryEmail = '';
          this.seller.sellerPrimaryEmail = '';
          this.seller.SellerSecondaryPhone = '';

          
          this.consignee.consigneeRif = '';
          this.consignee.consigneeName = ''; 
          this.consignee.consigneeAddress = ''; 
          this.consignee.consigneePrimaryPhone = '';
          this.consignee.consigneeSecondaryPhone = ''; 
          this.consignee.consigneePrimaryEmail = ''; 
          this.consignee.consigneeSecondaryEmail = ''; 

          this.notify.notifyToName = ''; 
          this.notify.notifyToAddress = ''; 
          this.notify.notifyToPrimaryPhone = ''; 
          this.notify.notifyToSecondaryPhone = ''; 
          this.notify.notifyToPrimaryEmail = ''; 
          this.notify.notifyToSecondaryEmail = ''; 

          this.customBroke.CustomBrokerRif= '';
          this.customBroke.CustomBrokerName= '',
          this.customBroke.CustomBrokerAddress= '',
          this.customBroke.CustomBrokerPrimaryPhone= '',
          this.customBroke.CustomBrokerSecondaryPhone= '',
          this.customBroke.CustomBrokerPrimaryEmail= '',
          this.customBroke.CustomBrokerSecondaryEmail= '',

          this.consolidator.ConsolidatorRif= '',
          this.consolidator.ConsolidatorName= '',
          this.consolidator.ConsolidatorAddress= '',
          this.consolidator.ConsolidatorPrimaryPhone= '',
          this.consolidator.ConsolidatorSecondaryPhone= '',
          this.consolidator.ConsolidatorPrimaryEmail= '',
          this.consolidator.ConsolidatorSecondaryEmail= ''

          this.featuresDescription = '';
        }
    },
  },
};
</script>